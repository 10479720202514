import React from 'react'

const Video = ({ videoSrcURL, videoTitle, ...props }) => (
  <div className="videoContainer">
    <video
    className="video"
      src={videoSrcURL}
      muted={true}
      playsInline={true}
      autoPlay="autoplay"
      loop
    ></video>
  </div>
)

export default Video
