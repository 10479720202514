import React from 'react'
import { navigate } from 'gatsby'
import { Spring, Transition } from 'react-spring/renderprops'
import { TransitionState } from 'gatsby-plugin-transition-link'

function Transitioner(props) {

  const { children } = props

  return (

  <TransitionState>

    {({ transitionStatus, exit, entry }) => {
      const mount = ['entering', 'entered'].includes(transitionStatus)
      const seconds = mount ? entry.length : exit.length

      // if (props.location && transitionStatus === 'entering') {
      //   navigate(`${props.location}`);
      // } 

      return (
        <Spring
          to={{
            opacity: mount ? 1 : 0,
          }}
          config={{
            duration: seconds * 1000,
          }}
          onRest={() => {
            props.location ? 
            navigate(`${props.location}`) : null
          }}
        >
          {props => <div style={props}>{children}</div>}
        </Spring>
      )
    }}
  </TransitionState>
  )
}

export default Transitioner
