import React from 'react'
import Video from './video.js'
import Img from 'gatsby-image'

const Media = props => (
  <div className="mediaContainer">
    {props.videoCheck === 'mp4' ? (
      <Video videoSrcURL={props.videoSrcURL} className={props.styleClass} />
    ) : (
      <Img
        alt={props.imgAlt}
        className={props.styleClass}
        sizes={props.imgSizes}
      />
    )}
  </div>
)

export default Media
