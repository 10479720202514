import React from 'react'
import { Link } from 'gatsby'
import styles from './navigation.module.scss'
import { useSpring, animated, config } from 'react-spring'
import TransitionLink from 'gatsby-plugin-transition-link'

function Navigation(props) {
  const { mainPage } = props

  const navLinks = useSpring({
    config: config.slow,
    from: { top: -200 },
    to: { top: 50 },
    delay: !mainPage ? 0 : 800,
    // immediate: props.topBarState,
  })

  const bottomLinks = useSpring({
    config: config.slow,
    from: { bottom: -200 },
    to: { bottom: 0 },
    delay: !mainPage ? 0 : 800,
    // immediate: props.topBarState,
  })

  return (
    <nav>
      <animated.div className={styles.topNav} style={navLinks}>
        <div className={styles.linksNav}>
          <div className={styles.navLeft}>
            {mainPage ? (
              <Link to={`/#hero`} className={styles.projectLink}>
                Home
              </Link>
            ) : (
              <TransitionLink
                to={`/`}
                exit={{ length: 0.6 }}
                entry={{ delay: 0.8, length: 1 }}
                className={styles.projectLink}
              >
                Home
              </TransitionLink>
            )}

            <svg
              className={`${styles.circleLeft} ${styles.circle}`}
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <ellipse
                  className={styles.foreground}
                  ry="22"
                  rx="45"
                  cy="23"
                  cx="61"
                  strokeWidth="1"
                />
              </g>
            </svg>
          </div>

          <div className={styles.navRight}>
            <div className={styles.clientLinkLeft}>
              <TransitionLink
                to={'/projects'}
                exit={{ length: 0.6 }}
                entry={{ delay: 0.8, length: 1 }}
                className={styles.clientLink}
              >
                Projects
              </TransitionLink>
              <svg
                className={`${styles.circle} ${styles.circleProjects}`}
                xmlns="http://www.w3.org/2000/svg"
              >
                <g>
                  <ellipse
                    className={styles.foreground}
                    ry="22"
                    rx="55"
                    cy="23"
                    cx="61"
                    strokeWidth="1"
                  />
                </g>
              </svg>
            </div>

            <div>
              {mainPage ? (
                <Link to={`/#footer`} className={styles.clientLink}>
                  Info
                </Link>
              ) : (
                <TransitionLink
                  to={`/#footer`}
                  exit={{ length: 0.6 }}
                  entry={{ delay: 0.8, length: 1 }}
                  className={styles.clientLink}
                >
                  Info
                </TransitionLink>
              )}
              <svg
                className={`${styles.circle} ${styles.circleRight}`}
                xmlns="http://www.w3.org/2000/svg"
              >
                <g>
                  <ellipse
                    className={styles.foreground}
                    ry="22"
                    rx="45"
                    cy="23"
                    cx="61"
                    strokeWidth="1"
                  />
                </g>
              </svg>
            </div>
          </div>
        </div>
      </animated.div>
      <animated.div className={styles.bottomNav} style={bottomLinks}>
        <div>
          <TransitionLink
            to={'/projects'}
            exit={{ length: 0.6 }}
            entry={{ delay: 0.8, length: 1 }}
            className={styles.clientLinkBottom}
          >
            Project List
          </TransitionLink>
        </div>
      </animated.div>
    </nav>
  )
}

export default Navigation
