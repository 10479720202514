import React from 'react'
import base from '../layouts/base.scss'
import Helmet from 'react-helmet'
import Navigation from '../components/navigation'
import favicon from '../assets/logos/artefactFaviconWhite.png'
import SEO from '../components/SEO.js'
import { useStaticQuery, graphql } from 'gatsby'

function Layout(props) {

  const { children } = props

  const { allSite } = useStaticQuery(
    graphql`
      query {
        allSite {
          edges {
            node {
              siteMetadata {
                author
                description
                image
                title
                url
                lang
              }
            }
          }
        }
      }
    `
  )

  const site = allSite.edges[0].node.siteMetadata
  const title = site.title;
  const lang = site.lang;
  const description = site.description;
  const meta = [];

  return (
    <div
      style={{
        maxWidth: '1500px',
        margin: '0 auto',
        width: '85%',
        backgroundColor: 'white',
      }}
    >
      <SEO 
        title={title}
        meta={meta}
        site={site}
        lang={lang}
        description={description}
      />
      <Helmet>
        <link
          rel="stylesheet"
          href="https://use.typekit.net/jjh2xop.css"
        ></link>
        <link rel="icon" href={favicon} />
      </Helmet>

      {children}
    </div>
  )
}

export default Layout
